/**
 * 数据工具类
 */
const DataUtil = {
  /**
   * 用目标对象的属性值重写原始对象的属性值
   * @param source 原始对象
   * @param target 目标对象
   */
  rewrite: (source, target) => {
    for (let p in source) {
      if (target[p]) {
        source[p] = target[p];
      }
    }
  },
  /**
   * 克隆对象
   * @param source 原始对象
   * @returns {any}
   */
  clone: (source) => {
    return JSON.parse(JSON.stringify(source));
  },
};
export { DataUtil };
